import http_documental from "../http-common-documental";

class DocumentalService {
    getPorRut(rut) {
        return http_documental.get(`/rrhhgd/persona/${rut}`);
    }

    getById(id) {
        return http_documental.get(`/rrhhgd/${id}`);
    }

    updateGD(id, data) {
        return http_documental.put(`/rrhhgd/${id}`, data);
    }

    ingresarGD(data) {
        return http_documental.post('/rrhhgd', data);
    }

    deleteGD(id) {
        return http_documental.delete(`/rrhhgd/${id}`);
    }

    uploadFile(rut, formData) {
        return http_documental.post(`/rrhhgd/uploadfile/${rut}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

export default new DocumentalService();