import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { useAuth } from '../authprovide.component';

/** Servicios */
import categoriaService from '../../services/categoria.service';
import documentalService from '../../services/documental.service';
import documentosService from '../../services/documentos.service';
import personasService from '../../services/personas.service';

/** Componentes */
import AutoComplete from '../autocomplete.componet';

/** Material UI */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { List, ListItem, ListItemText } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

const S3_BUCKET = 'docreclutav2';
const REGION = 'sa-east-1';

const UploadPage = () => {
    const [personas, setPersonas] = useState([]);
    const [persona, setPersona] = useState('');
    const [gestion, setGestion] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [documentos, setDocumentos] = useState([]);
    const [maetroDocumentos, setMaestroDocumentos] = useState([]);
    const auth = useAuth();
    const [ingreso, setIngreso] = useState(true);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {
        retreivePersonas();
        if (auth) {
            setGestion({ ...gestion, ['author']: auth.user.nombre });
        }
    }, [auth]);

    async function retreiveCategorias() {
        setLoading(true);
        categoriaService.getAll()
            .then(resp => {
                setCategorias(resp.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    async function retreivePersonas() {
        setLoading(true);
        personasService.getAll()
            .then(data => {
                setPersonas(data.data);
                retreiveMaestroDocumentos();
                retreiveCategorias();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    async function retreiveMaestroDocumentos() {
        setLoading(true);
        documentosService.getAll()
            .then(data => {
                data.data.sort(function (a, b) {
                    if (a.nombre < b.nombre) {
                        return -1;
                    }
                    if (a.nombre > b.nombre) {
                        return 1;
                    }
                    return 0;
                });
                setMaestroDocumentos(data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const buscarPersona = () => {
        setLoading(true);
        const valor = document.getElementById("autocompletar");
        if (!valor.value) {
            alert("Falta completar un campo !!");
            setLoading(false);
            return;
        }
        const r = valor.value.split(' - ');
        const rut2 = r[1];
        if (!rut2) {
            alert("Debe hacer clic en la persona encontrada!!");
            setLoading(false);
            return;
        }
        var per = personas.find(v => v.Rut === rut2);
        setPersona(per);
        var rut_ = rut2.replaceAll('.', '');
        documentalService.getPorRut(rut_)
            .then(data => {
                setDocumentos(data.data);
                setLoading(false);
                setIngreso(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const handleGestion = (valor, nombre) => {
        setGestion({ ...gestion, [nombre]: valor });
    }

    function retreiveDocs() {
        var _rut = persona.Rut.replaceAll('.', '');
        documentalService.getPorRut(_rut)
            .then(data => {
                setDocumentos(data.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const ingresarGD = () => {
        if (!gestion.name) {
            enqueueSnackbar("Falta ingresar el Nombre", { variant: 'info' });
            return;
        }
        if (gestion.no_aplica == "NA") {
            if (!gestion.valid_from) {
                enqueueSnackbar("Falta ingresar la fecha de inicio", { variant: 'info' });
                return;
            }
            if (!gestion.valid_through) {
                enqueueSnackbar("Falta ingresar la fecha de termino", { variant: 'info' });
                return;
            }
        }

        if (!gestion.author) {
            enqueueSnackbar("Falta ingresar el Autor", { variant: 'info' });
            return;
        }
        if (!gestion.archivo) {
            enqueueSnackbar("Falta cargar el Archivo", { variant: 'info' });
            return;
        }

        if (!gestion.categoria) {
            enqueueSnackbar("Falta ingresar la categoria", { variant: 'info' });
            return;
        }
        setLoading(true);
        var code = persona.Rut.replaceAll('.', '');
        const dataGestion = {
            valid_from: gestion.valid_from,
            valid_through: gestion.valid_through,
            author: gestion.author,
            code: code,
            name: gestion.name
        }
        //Ingresamos los datos
        // TO DO

        if (gestion.archivo) {
            /** FORM DATA */
            const formData = new FormData();
            formData.append('file',gestion.archivo[0]);
            documentalService.uploadFile(code, formData)//ReactS3Client.uploadFile(gestion.archivo[0], gestion.name + "." + extension)
                .then(data => {
                    //Actualizamos la info.
                    dataGestion.ruta = data.data;//data.location;
                    documentalService.ingresarGD(dataGestion)
                        .then(resp => {
                            retreivePersonas();
                            retreiveDocs();
                            setLoading(false);
                            enqueueSnackbar("Archivo subido correctamente!!!", { variant: 'success' });
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
        }
    };

    const onChangeNa = (e) => {
        if (e.target.checked) {
            setGestion({ ...gestion, ['no_aplica']: "NA" });
        } else {
            setGestion({ ...gestion, ['no_aplica']: "" });
        }
    }

    return (
        <Container>
            {loading ? (
                <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
            ) : ""}
            <Box>
                <FormControl fullWidth>
                    <Stack direction="column" spacing={2} maxWidth>
                        <AutoComplete suggestions={personas.map(({ perApMaterno, perNombre1, perApPaterno, Rut }) => perNombre1 + " " + perApPaterno + " " + perApMaterno + " - " + Rut)} />
                        <p style={{ margin: 1, color: 'blue', fontSize: 16 }}>Buscar por nombre ó Rut (con puntos y guión)</p>
                        <Button variant='contained' onClick={buscarPersona}>Buscar</Button>
                    </Stack>
                </FormControl>
            </Box>
            {persona ? (
                <Box
                    sx={{
                        margin: 3,
                        padding: 2,
                        boxShadow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    {persona.perNombre1.charAt(0).toUpperCase()}
                                </Avatar>
                            }
                            /* action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            } */
                            title={persona.perNombre1 + " " + persona.perApPaterno + " " + persona.perApMaterno}
                            subheader={persona.ctoDescripcion}
                        />
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {persona.recDescripcion}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {persona.estado === true ? "Activo" : "Inactivo"}
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            ) : ""}
            <Box
                sx={{
                    margin: 3,
                    boxShadow: 1,
                    padding: 2
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="nombre">Nombre</InputLabel>
                            <Select
                                labelId="nombre"
                                id="nombre"
                                label="Nombre"
                                onChange={(e) => handleGestion(e.target.value, 'name')}
                                disabled={ingreso}
                            >
                                {maetroDocumentos.map(md => (
                                    <MenuItem key={md.nombre} value={md.nombre}>{md.nombre}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="categoria">Categoria</InputLabel>
                            <Select
                                labelId="categoria"
                                id="categoria"
                                label="Categoria"
                                onChange={(e) => handleGestion(e.target.value, 'categoria')}
                                disabled={ingreso}
                            >
                                {categorias.map(md => (
                                    <MenuItem key={md.nombre} value={md.nombre}>{md.nombre}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                label="Inicio de Vigencia"
                                value={gestion.valid_from ? gestion.valid_from : ""}
                                onChange={(newValue) => handleGestion(newValue, 'valid_from')}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={ingreso}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                label="Termino de Vigencia"
                                value={gestion.valid_through ? gestion.valid_through : ""}
                                onChange={(newValue) => handleGestion(newValue, 'valid_through')}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={ingreso}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField label="Autor" onChange={(e) => handleGestion(e.target.value, 'author')} fullWidth value={auth.user.nombre} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel control={<Checkbox onChange={(e) => onChangeNa(e)} />} label="Activar Alarma" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button variant='contained' component='label' startIcon={<AttachFileIcon />} disabled={ingreso}>
                            Cargar
                            <input hidden type="file" onChange={(e) => handleGestion(e.target.files, 'archivo')} />
                        </Button>
                        {gestion?.archivo ? <p>{gestion.archivo[0].name}</p> : ""}
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    margin: 3,
                    alignItems: 'end',
                    justifyContent: 'right',
                    display: 'flex'
                }}
            >
                <Button variant='contained' onClick={ingresarGD} disabled={ingreso}>Subir</Button>
            </Box>
            <Box sx={{
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <List>
                    {documentos.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Container>
    )
}

export default function UploadPageSnack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <UploadPage />
        </SnackbarProvider>
    )
}